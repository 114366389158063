.datatable-container {
    padding: 10px;
    width: 100%;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap;
  }
  
  .header-left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .p-inputgroup {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  
  .p-datatable-responsive .p-datatable-thead > tr > th,
  .p-datatable-responsive .p-datatable-tbody > tr > td {
    word-break: break-word;
    white-space: normal;
  }
  
  .p-datatable .p-paginator-bottom {
    border: none;
  }
  
  .table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
  }
  
  .page-size-dropdown {
    margin-left: 10px;
  }
  
  .item-image {
    width: 50px;
    height: 50px;
  }
  
  @media (max-width: 768px) {
    .table-header {
      flex-direction: column;
    }
    .header-left, .header-right {
      width: 100%;
      justify-content: center;
      margin-bottom: 10px;
    }
    .p-inputgroup {
      width: 100%;
      margin-bottom: 10px;
    }
    .header-right {
      justify-content: center;
      width: 100%;
    }
  }
  
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

.p-menuitem span {
  color: #000;
}
.font-800 {
  font-weight: 800;
}
pi {
  font-family: "primeicons !important";
}
* {
  font-family: "Lato", sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.costume-form input {
  border: 1px solid #18496f !important;
  height: 48px;
}
.costume-form .p-dropdown {
  border: 1px solid #18496f !important;
  height: 48px;
  align-items: center;
}

.costume-form .text-editor {
  border: 1px solid #18496f !important;
  max-width: 100%;
  width: 100%;
}

.h3remPhone {
  height: 3rem !important;
}
.h3remPhone .PhoneInputInput {
  height: 3rem !important;
}
.dialog-costume .p-dialog-header {
  background-color: #18496f !important;
  color: #fff;
}
.bg-core {
  background-color: #18496f;
}
.btn-core {
  background-color: #18496f;
  height: 48px;
  border: unset !important;
}
.btn-core:hover {
  background-color: #000;
}
.text-core {
  color: #18496f;
}

.dialog-costume .p-dialog-header-icon {
  color: #fff !important;
}

.h3remPhone .PhoneInputCountry {
  position: relative;
  border: 1px solid #18496f !important;
  width: 75px;
  margin-right: 10px;
}
.h3remPhone .PhoneInputCountryIcon {
  position: absolute;
  left: 17px;
}
.h3remPhone .PhoneInputCountrySelectArrow {
  right: 10px;
  color: #000;
  position: absolute;
  font-size: 24px;
  top: 18px;
}
.h3remPhoneInput input {
  border: 1px solid #18496f !important;
}
.h3remPhoneInput .p-dropdown {
  border: 1px solid #18496f;
}

.btn-danger-outlined {
  border: 1px solid #ef4444 !important;
  color: #ef4444 !important;
}
.btn-danger-outlined:hover {
  background-color: #ef4444 !important;
  color: #fff !important;
}

.btn-succes-outlined {
  border: 1px solid #689f38 !important;
  color: #689f38 !important;
}
.btn-succes-outlined:hover {
  background-color: #689f38 !important;
  color: #fff !important;
}

/* .datatable-container {
  background-color: #fff;
} */
.p-datatable .p-datatable-header {
  background-color: #fff;
}
.p-datatable .p-datatable-footer {
  background: #ffffff;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.shadow1 {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.card-register-new {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 1020px;
}
.card-register-new .p-icon-field {
  width: 100% !important;
}
.card-register-new .-mt-8px {
  margin-top: -8px;
}
.card-register-new .bener {
  width: 100%;
  border-radius: 20px 0 0 20px;
}
.register-container {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* min-height: 100vh; */
  background-color: #f5f7fc;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fc;
}
.login-container input {
  width: 100%;
}
.register-card {
  width: 400px;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: white;
}
.register-logo {
  display: block;
  margin: 0 auto 2rem;
  width: 200px;
}
.p-invalid {
  border-color: var(--red-500) !important;
}
.p-error {
  color: var(--red-500);
}
.p-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.p-mt-2 {
  margin-top: 0.5rem;
}
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  background-color: #f5f7fc;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    zoom: 0.98 !important;
  }
  .card-register-new {
    max-width: 950px;
  }
}

.selectpage {
  background-color: #f7f8fc;
  min-height: calc(100vh - 3.7rem);
  margin: -1.5rem;
}

.selectpage-new {
  max-width: 1100px;
  margin: auto;
}
.selectpage-new .cardnya {
  background-color: #fff;
  height: 100%;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.selectpage-new .tagnya {
  text-transform: uppercase;
  background-color: #f6f6f6;
  color: #000;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  display: inline-flex;
  padding: 10px 1rem;
  font-weight: bold;
}

.selectpage-new .harganya {
  font-weight: 800;
  font-size: 32px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 0.9;
}
.selectpage-new .harganya {
  color: #0288d1;
}
.selectpage-new .harganya span {
  font-size: 16px;
}
.selectpage-new .deskripsi {
  font-size: 18px;
  line-height: 1.5;
  margin-top: -15px;
}
.selectpage-new .btn-paket {
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
  padding: 15px 0;
  font-size: 18px;
  justify-content: center;
}
.selectpage-new .btn-paket span {
  padding-right: 10px;
}
.selectpage-new .judul {
  font-size: 38px;
  font-weight: 800;
}

.selectpage-new .listnya {
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
}
.selectpage-new .listnya i {
  padding-right: 10px;
}
.selectpage-new .listnya:last-child {
  border: none !important;
}

.login-logo {
  display: block;
  margin: 0 auto 2rem;
  width: 200px;
}

.card-login-new {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: #fff;
  border-radius: 20px;
  width: 500px !important;
}

.card-paket {
  background-color: #fff;
  border-radius: 14px;
  padding: 2rem;
  border: 1px solid #d8d8d8;
}
/* border-bottom: 1px solid #000; */

@media only screen and (max-width: 768px) {
  .card-register-new {
    height: 100% !important;
    border-radius: 0 !important;
  }
  .card-register-new .bener {
    height: 100vh !important;
    border-radius: 0 !important;
    object-fit: cover;
  }
  .register-container {
    padding: 0 !important;
  }
  .selectpage-new .judul {
    font-size: 28px;
    font-weight: 800;
  }
  .selectpage-new .cardnya {
    font-size: 14px;
    padding: 1.5rem;
  }
  .selectpage-new .deskripsi {
    font-size: 16px;
    line-height: 1.5;
    margin-top: -15px;
  }
  .login-container {
    background-color: #fff;
  }
  .card-login-new {
    box-shadow: unset !important;
  }
}

.grid-paket1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.costume-form-paket {
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
  .p-dropdown {
    width: 100% !important;
  }
  .title-input {
    margin-bottom: 10px;
    font-weight: 700;
  }
}

.grid-form-spec {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
}
.list-spec {
  display: flex !important;
  .d1 {
    width: 100%;
  }
  .d2 {
    width: 300px;
    padding-left: 20px;
    button {
      width: 100%;
      background-color: #063746;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
      font-size: 16px;
      height: 47px;
    }
  }
}

.card-spec {
  margin-left: 3rem;
  background-color: #ffefcb;
  padding: 25px;
  padding-top: 10px;
  border-radius: 10px;
  .card-dalam {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 7px;
    display: flex;
    .d1 {
      width: 100%;
    }
    .d2 {
      display: flex;
      justify-content: end;
      width: 250px;
    }
  }
}

.text-editor {
  width: 100% !important;
  max-width: 100% !important;
}
.tiptap {
  width: 100% !important;
  max-width: 100% !important;
}

.card-jamaah-transaksi {
  background-color: #ffefcb;
  padding: 15px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.text-editor {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #ffffff;
    max-width: 420px;
  }
.text-editor > .info {
    font-size: 12px;
    color: #999999;
}
  .toolbar {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
  
  .tiptap {
    min-height: 200px;
    max-width: 420px;
    padding: 5px;
  }
  
  .tiptap > p {
    margin: 0;
    padding: 0;
  }